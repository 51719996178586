import Title from "@components/UI-Elements/Typography/Title";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Link from "../components/UI-Elements/Navigation/Link";

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="text-center">
        <StaticImage
          alt="Ghost getting abducted by aliens"
          className="block mx-auto w-1/2"
          src="../images/abduction-illustration.svg"
        />
        <Title level={2} className="inline-block my-8 p-3">
          🎉  Glückwunsch! 🎉  <br/>Du hast eine nicht erreichbare Seite gefunden. 
        </Title>
        <p>Falls du uns einen Fehler melden möchtest, kannst du dies <Link href="/contact/" type="text">hier gerne tun.</Link></p>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
